// Hooks
const useChildGroups = require("./src/hooks/use-child-groups");
const useEventTypes = require("./src/hooks/use-event-types");
const useNewsCategories = require("./src/hooks/use-news-categories");
const usePersonalRoles = require("./src/hooks/use-personal-roles");
const usePublicationYears = require("./src/hooks/use-publication-years");
const useResearchAreas = require("./src/hooks/use-research-areas");
const useSemesters = require("./src/hooks/use-semesters");
const useSites = require("./src/hooks/use-sites");
const useNavHierarchies = require("./src/hooks/use-nav-hierarchies")

// Algolia Queries
const articlesQuery = require("./src/algolia-queries/articles");
const eventsQuery = require("./src/algolia-queries/events");
const coursesQuery = require("./src/algolia-queries/courses");
const groupSiteSearchQuery = require("./src/algolia-queries/group-site-search");
const peopleQuery = require("./src/algolia-queries/people");
const visitorsQuery = require("./src/algolia-queries/visitors");
const publicationsQuery = require("./src/algolia-queries/publications");
const siteSearchQuery = require("./src/algolia-queries/site-search");

module.exports = {
  useChildGroups,
  useEventTypes,
  useNewsCategories,
  usePersonalRoles,
  usePublicationYears,
  useResearchAreas,
  useSemesters,
  useSites,
  articlesQuery,
  eventsQuery,
  groupSiteSearchQuery,
  peopleQuery,
  visitorsQuery,
  publicationsQuery,
  coursesQuery,
  siteSearchQuery,
  useNavHierarchies,
};
