import React from "react";
import classNames from "classnames";
import "./TextHTML.scss";

const TextHTML: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  className,
  children,
}) => {
  const classList = classNames({
    "text-content": true,
    [`${className}`]: !!className,
  });
  return (
    <div className={classList}>
      {children}
    </div>
  );
};

export default TextHTML;